import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/46/#" className="blue">
                  {" "}
                  <p>
                    <span>
                      <span>NEUTROGENA</span>
                    </span>
                    <sup>
                      <span>
                        <span>®</span>
                      </span>
                    </sup>
                    <span>
                      <span>
                        {" "}
                        Norská receptura Výživné tělové mléko Nordic Berry
                      </span>
                    </span>
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-NordicBerry-250ml-3D.png"
                      alt="NTG NordicBerry 250ml 3D"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      <span>
                        <span>NEUTROGENA</span>
                      </span>
                      <sup>
                        <span>
                          <span>®</span>
                        </span>
                      </sup>
                      <span>
                        <span>
                          {" "}
                          Norská receptura Výživné tělové mléko Nordic Berry
                        </span>
                      </span>
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span>
                          <span>
                            Každodenní výzvy způsobují, že naše pokožka je suchá
                            a necítí se dobře. Je klinicky prokázáno, že
                            unikátní inovativní výživná receptura obohacená o
                            ostružiník moruška spolu s přirozenými procesy
                            pokožky až trojnásobně zvyšují stupeň hydratace
                            pokožky* a suchou pokožku přeměňují v hebkou a
                            vyživenou každý den. Pokožka vypadá krásně a zdravě.
                            Tělové mléko je vhodné pro citlivou pokožku.{" "}
                          </span>
                          <span>
                            Krémová, nemastná textura s jemnou vůní se rychle
                            vstřebává.{" "}
                          </span>
                        </span>
                        <br />
                        <span>
                          <span>
                            * hladina hydratace pokožky 7 hodin po aplikaci
                          </span>
                        </span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte denně na celé tělo, ideálně po koupeli či
                      sprchování.{" "}
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>250 ml; 400 ml</dd>
                  </dl>
                </div>
                <div id="fb_10"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/46/"
                  >
                    <img
                      src="/assets/Uploads/NTG-NordicBerry-250ml-3D.png"
                      alt="NTG NordicBerry 250ml 3D"
                    />
                    <span className="txt">
                      <p>
                        <span>
                          <span>NEUTROGENA</span>
                        </span>
                        <sup>
                          <span>
                            <span>®</span>
                          </span>
                        </sup>
                        <span>
                          <span>
                            {" "}
                            Norská receptura Výživné tělové mléko Nordic Berry
                          </span>
                        </span>
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/47/"
                  >
                    <img
                      src="/assets/Uploads/NTG-bcpversion2a.jpg"
                      alt="NTG bcpversion2a"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Výživný tělový
                        balzám Nordic Berry
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/59/"
                  >
                    <img
                      src="/assets/Uploads/comfort-balm-200ML.png"
                      alt="comfort balm 200ML"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Hloubkově
                        hydratační balzám
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/61/"
                  >
                    <img
                      src="/assets/Uploads/ultra-nourishing-BALM-200ML2.png"
                      alt="ultra nourishing BALM 200ML2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Ultra výživný
                        intenzivní balzám
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/62/"
                  >
                    <img
                      src="/assets/Uploads/NTG-body-lotion-250ml-suchapokozka.png"
                      alt="NTG body lotion 250ml suchapokozka"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Hloubkově
                        hydratační tělové mléko
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
